﻿import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

ScrollTrigger.matchMedia({

    "(min-width: 768px)": function () {
        const sections = gsap.utils.toArray("section");
        var refreshPriority = sections.length;
        sections.forEach(container => {
            if (container.classList.contains("scroll-content-wrapper")) {
                const scrollPin = gsap.utils.toArray(container.querySelectorAll(".scroll-pin"))[0];
                const slidesText = gsap.utils.toArray(container.querySelectorAll(".content"));
                const slidesImages = gsap.utils.toArray(container.querySelectorAll(".scroll-img"));
                const slidePips = gsap.utils.toArray(container.querySelectorAll(".slide-pip"));
                var i = 0;

                const tl = gsap.timeline();
                slidesImages.forEach(slide => {
                    if (slidesImages[0] !== slide) {
                        tl.fromTo(slide,
                            {
                                opacity: 1,
                                xPercent: 110
                            },
                            {
                                duration: 1,
                                xPercent: 0,
                                opacity: 1
                            });

                        tl.fromTo(slidesText[i - 1],
                            {
                                xPercent: 0,
                                opacity: 1
                            },
                            {
                                duration: 1,
                                opacity: 0,
                                xPercent: -110
                            },
                            "-=1");
                        tl.fromTo(slidesText[i],
                            {
                                xPercent: 110,
                                opacity: 0
                            },
                            {
                                duration: 1,
                                opacity: 1,
                                xPercent: 0
                            },
                            "-=1");

                        tl.fromTo(slidePips[i - 1],
                            {
                                className: "slide-pip active"
                            },
                            {
                                duration: 0,
                                className: "slide-pip"
                            },
                            "-=0.5");
                        tl.fromTo(slidePips[i],
                            {
                                className: "slide-pip"
                            },
                            {
                                duration: 0,
                                className: "slide-pip active"
                            },
                            "-=0.5");
                    }
                    i++;
                });

                ScrollTrigger.create({
                    animation: tl,
                    trigger: scrollPin,
                    start: "top",
                    end: "bottom",
                    scrub: 0.5,
                    pin: true,
                    refreshPriority: refreshPriority,
                    snap: 1 / (slidesImages.length - 1)
                });
            }
            refreshPriority--;
        });
    }
});